<template>
  <b-container fluid>
    <b-row v-if="isApplicable">
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle  class="text-center">
              <div class="text-center">
                <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('externalUserIrrigation.scheme_application') }}</h5>
              </div>
          </template>
          <template v-slot:body>
            <b-overlay :show="load">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(resunkStore)" @reset.prevent="reset" >
                    <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                            <h5 style="font-weight:bold">{{ $t('externalUserIrrigation.farmer_info') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Organization" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro.organization')"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                    plain
                                    v-model="schemeApplication.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">
                                        {{$t('globalTrans.select')}}
                                      </b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Name" vid='name' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('user.name')"
                                  label-for="name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <b-form-input
                                    id="name"
                                    v-model="schemeApplication.name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Father name" vid='father_name' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('externalUserIrrigation.father_name')"
                                  label-for="father_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <b-form-input
                                  id="father_name"
                                  v-model="schemeApplication.father_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mother name" vid='mother_name' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('externalUserIrrigation.mother_name')"
                                  label-for="mother_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <b-form-input
                                  id="mother_name"
                                  v-model="schemeApplication.mother_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="NID" vid='nid' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('externalUserIrrigation.nid')"
                                  label-for="nid"
                                  slot-scope="{ valid, errors }"
                              >
                                  <b-form-input
                                  type="number"
                                  id="nid"
                                  v-model="schemeApplication.nid"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Division" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('org_pro_division.division')"
                                  label-for="far_division_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-select
                                  plain
                                  v-model="schemeApplication.far_division_id"
                                  :options="divisionList"
                                  id="far_division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upazila" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('org_pro_upazilla.upazilla')"
                                  label-for="far_upazila_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-select
                                  plain
                                  v-model="schemeApplication.far_upazilla_id"
                                  :options="upazilaListComp"
                                  id="far_upazila_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Village" vid='far_village' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('externalUserIrrigation.village')"
                                  label-for="far_village"
                                  slot-scope="{ valid, errors }"
                              >
                                  <b-form-input
                                  id="far_village"
                                  v-model="schemeApplication.far_village"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Scheme Type" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('irrigation_config.scheme_type')"
                                    label-for="scheme_type_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    plain
                                    v-model="schemeApplication.scheme_type_id"
                                    :options="schemeTypeList"
                                    id="scheme_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option value="" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('user.name_bn')"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="name_bn"
                                    v-model="schemeApplication.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Father name (bn)" vid='father_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.father_name_bn')"
                                    label-for="father_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="father_name_bn"
                                    v-model="schemeApplication.father_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mother name (bn)" vid='mother_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.mother_name_bn')"
                                    label-for="mother_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="mother_name_bn"
                                    v-model="schemeApplication.mother_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mobile" vid='far_mobile_no' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('user.phone_no')"
                                    label-for="far_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    type="number"
                                    id="far_mobile_no"
                                    v-model="schemeApplication.far_mobile_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="District" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('org_pro_district.district')"
                                    label-for="far_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    plain
                                    v-model="schemeApplication.far_district_id"
                                    :options="districtListComp"
                                    id="far_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Union" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('org_pro_union.union')"
                                    label-for="far_union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                    plain
                                    v-model="schemeApplication.far_union_id"
                                    :options="unionListComp"
                                    id="far_union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                                <ValidationProvider name="Village (bn)" vid='far_village_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.village_bn')"
                                    label-for="far_village_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="far_village_bn"
                                    v-model="schemeApplication.far_village_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="6" class="mb-4">
                            <h5 style="font-weight:bold">{{ $t('externalUserIrrigation.scheme_manager_info') }}</h5>
                        </b-col>
                        <b-col v-if="!applicationFound" lg="6" sm="6" class="mb-4 text-right">
                            <b-form-checkbox
                            @change="setschemeAddress"
                            >
                            {{ $t('externalUserIrrigation.same_as_farmer_info') }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Name" vid='sch_man_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('user.name')"
                                label-for="sch_man_name"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_name"
                                v-model="schemeApplication.sch_man_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Father name" vid='sch_man_father_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.father_name')"
                                label-for="sch_man_father_name"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_father_name"
                                v-model="schemeApplication.sch_man_father_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mother name" vid='sch_man_mother_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.mother_name')"
                                label-for="sch_man_mother_name"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_mother_name"
                                v-model="schemeApplication.sch_man_mother_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="NID" vid='sch_man_nid' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.nid')"
                                label-for="sch_man_nid"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                type="number"
                                id="sch_man_nid"
                                v-model="schemeApplication.sch_man_nid"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Division" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_division.division')"
                                label-for="sch_man_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.sch_man_division_id"
                                :options="divisionList"
                                id="sch_man_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upazila" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_upazilla.upazilla')"
                                label-for="sch_man_upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.sch_man_upazilla_id"
                                :options="schManUpazilaList"
                                id="sch_man_upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Village" vid='sch_man_village' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.village')"
                                    label-for="sch_man_village"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="sch_man_village"
                                v-model="schemeApplication.sch_man_village"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Name (bn)" vid='sch_man_name_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('user.name_bn')"
                                label-for="sch_man_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_name_bn"
                                v-model="schemeApplication.sch_man_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Father name (bn)" vid='sch_man_father_name_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.father_name_bn')"
                                label-for="sch_man_father_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_father_name_bn"
                                v-model="schemeApplication.sch_man_father_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mother name (bn)" vid='sch_man_mother_name_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.mother_name_bn')"
                                label-for="sch_man_mother_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="mother_name_bn"
                                v-model="schemeApplication.sch_man_mother_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mobile" vid='sch_man_mobile_no' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('user.phone_no')"
                                label-for="sch_man_mobile_no"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                type="number"
                                id="sch_man_mobile_no"
                                v-model="schemeApplication.sch_man_mobile_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="District" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_district.district')"
                                label-for="sch_man_district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.sch_man_district_id"
                                :options="schManDistrictList"
                                id="sch_man_district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Union" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_union.union')"
                                label-for="sch_man_union_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.sch_man_union_id"
                                :options="schManUnionList"
                                id="sch_man_union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Village (bn)" vid='sch_man_village_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.village_bn')"
                                label-for="sch_man_village_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <b-form-input
                                id="sch_man_village_bn"
                                v-model="schemeApplication.sch_man_village_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="6" class="mb-4">
                            <h5 style="font-weight:bold">{{ $t('externalUserIrrigation.proposed_pump_address') }}</h5>
                        </b-col>
                        <b-col v-if="!applicationFound" lg="6" sm="6" class="mb-4 text-right">
                            <b-form-checkbox
                            @change="setPumpAddress"
                            id="same_as_sch_man_info"
                            name="same_as_sch_man_info"
                            value="0"
                            >
                            {{ $t('externalUserIrrigation.same_as_sch_man_info') }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Division" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_division.division')"
                                label-for="pump_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.pump_division_id"
                                :options="divisionList"
                                id="pump_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Upazila" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_upazilla.upazilla')"
                                label-for="pump_upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.pump_upazilla_id"
                                :options="pumpUpazilaList"
                                id="pump_upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump Mauza no" vid='pump_mauza_no' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_mauza_no')"
                                    label-for="pump_mauza_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="pump_mauza_no"
                                v-model="schemeApplication.pump_mauza_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump jl no" vid='pump_jl_no' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_jl_no')"
                                    label-for="pump_jl_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="pump_jl_no"
                                v-model="schemeApplication.pump_jl_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump plot no" vid='pump_plot_no' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_plot_no')"
                                    label-for="pump_plot_no"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="pump_plot_no"
                                v-model="schemeApplication.pump_plot_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="District" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_district.district')"
                                label-for="pump_district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.pump_district_id"
                                :options="pumpDistrictList"
                                id="pump_district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Union" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('org_pro_union.union')"
                                label-for="pump_union_id"
                                slot-scope="{ valid, errors }"
                                >
                                <b-form-select
                                plain
                                v-model="schemeApplication.pump_union_id"
                                :options="pumpUnionList"
                                id="pump_union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump Mauza no (bn)" vid='pump_mauza_no_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_mauza_no_bn')"
                                    label-for="pump_mauza_no_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                    id="pump_mauza_no_bn"
                                    v-model="schemeApplication.pump_mauza_no_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="applicationFound ? true : false "
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump jl no (bn)" vid='pump_jl_no_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_jl_no_bn')"
                                    label-for="pump_jl_no_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="pump_jl_no_bn"
                                v-model="schemeApplication.pump_jl_no_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Pump plot no (bn)" vid='pump_plot_no_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('externalUserIrrigation.pump_plot_no_bn')"
                                    label-for="pump_plot_no_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                <b-form-input
                                id="pump_plot_no_bn"
                                v-model="schemeApplication.pump_plot_no_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="applicationFound ? true : false "
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="6" class="mb-4">
                            <h5 style="font-weight:bold">{{ $t('externalUserIrrigation.attachment') }}</h5>
                        </b-col>
                        <b-col lg="6" sm="6" class="mb-4">
                            <h5 style="font-weight:bold">{{ $t('externalUserIrrigation.affidavit') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <ValidationProvider name="General minutes" vid='general_minutes'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.general_minutes')"
                                label-for="general_minutes"
                                slot-scope="{ valid, errors }"
                            >
                            <div v-if="applicationFound">
                              <a :href="baseUrl + schemeApplication.general_minutes" target="_blank" title="Scheme Land" class="mr-1"><i class="ri-download-cloud-fill"></i></a>
                            </div>
                              <b-form-file v-else
                                @change="onFileChangeGeneralMinutes"
                                v-model="schemeApplication.general_minutes"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Scheme Lands" vid='scheme_lands'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.scheme_lands')"
                                label-for="scheme_lands"
                                slot-scope="{ valid, errors }"
                            >
                              <div v-if="applicationFound">
                                <a :href="baseUrl + schemeApplication.scheme_lands" target="_blank" title="Scheme Land" class="mr-1"><i class="ri-download-cloud-fill"></i></a>
                              </div>
                              <b-form-file v-else
                                @change="onFileChangeSchemeLands"
                                v-model="schemeApplication.scheme_lands"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Scheme map" vid='scheme_map'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('externalUserIrrigation.scheme_map')"
                                label-for="scheme_map"
                                slot-scope="{ valid, errors }"
                            >
                              <div v-if="applicationFound">
                                <a :href="baseUrl + schemeApplication.scheme_map" target="_blank" title="Scheme Land" class="mr-1"><i class="ri-download-cloud-fill"></i></a>
                              </div>
                              <b-form-file v-else
                                @change="onFileChangeSchemeMap"
                                v-model="schemeApplication.scheme_map"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="Affidavit" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  :label="$t('irrigation_config.affidavit')"
                                  label-for="affidavit_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <b-form-select
                                  plain
                                  v-model="schemeApplication.affidavit_id"
                                  :options="affidavitList"
                                  id="affidavit_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="applicationFound ? true : false "
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                            <div v-if="affidavitData" style="border: 1px solid gray;box-shadow: 1px 2px 6px grey;color: gray">
                                <div style="padding:10px">
                                <h5 class="text-center" style='font-weight:bold'>{{ $t('irrigation_config.affidavit') }}</h5>
                                {{ affidavitData }}
                                </div>
                            </div>
                            <p class="text-dark"> Pump ID:    {{ pumpInfoId }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col></b-col>
                        <b-col>
                        <div class="mt-3">
                            <button class="btn btn-primary btn-block" type="submit">{{ saveBtnName }}</button>
                        </div>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <div class="box-message col-md-12">
        <div class="arrow">
          <div class="outer"></div>
          <div class="inner"></div>
        </div>
        <div class="message-body">
          <p class="pb-2 pt-2"><i class="ri-alert-fill mr-2"></i>{{ $t('externalUserIrrigation.resunk_applied_message') }}</p>
        </div>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerSchemeApplicationStore, operatorInfo } from '../../api/routes'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    this.checkOperator()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        load: true,
        saveBtnName: this.farmer_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        applicationFound: true,
        isApplicable: false,
        pumpInfoId: '',
        schemeApplication: {
            id: 0,
            org_id: '0',
            application_type_id: 2,
            scheme_type_id: '0',
            name: '',
            name_bn: '',
            father_name: '',
            father_name_bn: '',
            mother_name: '',
            mother_name_bn: '',
            nid: '',
            far_division_id: '0',
            far_district_id: '0',
            far_upazilla_id: '0',
            far_union_id: '0',
            far_village: '',
            far_village_bn: '',
            far_mobile_no: '',
            sch_man_name: '',
            sch_man_name_bn: '',
            sch_man_father_name: '',
            sch_man_father_name_bn: '',
            sch_man_mother_name: '',
            sch_man_mother_name_bn: '',
            sch_man_nid: '',
            sch_man_division_id: '0',
            sch_man_district_id: '0',
            sch_man_upazilla_id: '0',
            sch_man_union_id: '0',
            sch_man_village: '',
            sch_man_village_bn: '',
            sch_man_mobile_no: '',
            pump_division_id: '0',
            pump_district_id: '0',
            pump_upazilla_id: '0',
            pump_union_id: '0',
            pump_mauza_no: '',
            pump_mauza_no_bn: '',
            pump_jl_no: '',
            pump_jl_no_bn: '',
            pump_plot_no: '',
            pump_plot_no_bn: '',
            general_minutes: [],
            scheme_lands: [],
            scheme_map: [],
            affidavit_id: '0',
            status: 0,
            payment_status: 0
        },
        districtList: [],
        upazilaList: [],
        unionList: [],
        schManDistrictList: [],
        schManUpazilaList: [],
        schManUnionList: [],
        pumpDistrictList: [],
        pumpUpazilaList: [],
        pumpUnionList: [],
        affidavitData: '',
        farmer_id: '',
        amount: '',
        far_scheme_application_id: '',
        master_payment_id: '',
        baseUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    districtListComp () {
      return this.$store.state.ExternalUserIrrigation.commonObj.districtList
      .filter(item => this.schemeApplication.far_division_id === item.division_id && item.status === 0)
    },
    upazilaListComp () {
      return this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      .filter(item => this.schemeApplication.far_district_id === item.district_id && item.status === 0)
    },
    unionListComp () {
      return this.$store.state.ExternalUserIrrigation.commonObj.unionList
      .filter(item => this.schemeApplication.far_upazila_id === item.upazila_id && item.status === 0)
    },
    affidavitList: function () {
      return this.$store.state.ExternalUserIrrigation.affidavitList
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    },
    schemeTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.schemeTypeList
    }
  },
  watch: {
    'schemeApplication.far_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'schemeApplication.far_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'schemeApplication.far_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
      }
    },
    'schemeApplication.sch_man_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManDistrictList = this.getDistrictList(newVal)
      }
    },
    'schemeApplication.sch_man_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManUpazilaList = this.getUpazilaList(newVal)
      }
    },
    'schemeApplication.sch_man_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManUnionList = this.getUnionList(newVal)
      }
    },
    'schemeApplication.pump_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDistrictList = this.getDistrictList(newVal)
      }
    },
    'schemeApplication.pump_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpUpazilaList = this.getUpazilaList(newVal)
      }
    },
    'schemeApplication.pump_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpUnionList = this.getUnionList(newVal)
      }
    },
    'schemeApplication.affidavit_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAffidavitList(newVal)
      }
    },
    'schemeApplication.scheme_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAffidavitList(newVal)
      }
    }
  },
  methods: {
    setschemeAddress () {
        this.schemeApplication = Object.assign({}, this.schemeApplication,
        {
            sch_man_name: this.schemeApplication.name,
            sch_man_name_bn: this.schemeApplication.name_bn,
            sch_man_father_name: this.schemeApplication.father_name,
            sch_man_father_name_bn: this.schemeApplication.father_name_bn,
            sch_man_mother_name: this.schemeApplication.mother_name,
            sch_man_mother_name_bn: this.schemeApplication.mother_name_bn,
            sch_man_nid: this.schemeApplication.nid,
            sch_man_division_id: this.schemeApplication.far_division_id,
            sch_man_district_id: this.schemeApplication.far_district_id,
            sch_man_upazilla_id: this.schemeApplication.far_upazilla_id,
            sch_man_union_id: this.schemeApplication.far_union_id,
            sch_man_village: this.schemeApplication.far_village,
            sch_man_village_bn: this.schemeApplication.far_village_bn,
            sch_man_mobile_no: this.schemeApplication.far_mobile_no
        })
    },
    setPumpAddress () {
         this.schemeApplication = Object.assign({}, this.schemeApplication,
          {
            pump_division_id: this.schemeApplication.sch_man_division_id,
            pump_district_id: this.schemeApplication.sch_man_district_id,
            pump_upazilla_id: this.schemeApplication.sch_man_upazilla_id,
            pump_union_id: this.schemeApplication.sch_man_union_id
          })
    },
    onFileChangeGeneralMinutes (e) {
      this.schemeApplication.general_minutes = e.target.files[0]
    },
    onFileChangeSchemeLands (e) {
      this.schemeApplication.scheme_lands = e.target.files[0]
    },
    onFileChangeSchemeMap (e) {
      this.schemeApplication.scheme_map = e.target.files[0]
    },
    async resunkStore () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.schemeApplication.application_type_id = 2
      this.schemeApplication.payment_status = 1
      var formData = new FormData()
      Object.keys(this.schemeApplication).map(key => {
          formData.append(key, this.schemeApplication[key])
      })

      result = await RestApi.postData(irriSchemeServiceBaseUrl, farmerSchemeApplicationStore, formData, config)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.farmer_id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$router.push('/irrigation/scheme-application')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadinState)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getAffidavitList () {
      const affidavitList = this.$store.state.ExternalUserIrrigation.affidavitList
      const data = affidavitList.find(affidavit => affidavit.value === this.schemeApplication.scheme_type_id)
      if (data) {
        if (this.$i18n.locale === 'bn') {
          this.affidavitData = data.affidavit_bn
        } else {
          this.affidavitData = data.affidavit
        }
      } else {
        this.affidavitData = ''
      }
    },
    checkOperator () {
      const params = { operator_id: this.$store.state.Auth.authUser.id }
      RestApi.getData(irriSchemeServiceBaseUrl, operatorInfo, params).then(response => {
        if (response.success) {
          if (response.resunkStatus === 2) {
            this.applicationFound = true
            this.isApplicable = false
          } else {
            this.pumpInfoId = response.pumpInfoId
            this.applicationFound = false
            this.isApplicable = true
          }
          this.schemeApplication = Object.assign(this.schemeApplication, response.data)
        } else {
          this.applicationFound = true
        }
        this.load = false
      })
    }
  }
}
</script>
